import { RGBColor } from "react-color";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

function colourAsRgba(colour: RGBColor): string {
  return `rgba(${colour.r}, ${colour.g}, ${colour.b}, ${colour.a || "1"})`;
}

export { classNames, colourAsRgba };
