import { useField } from "formik";
import { HTMLProps } from "react";
import { classNames } from "../../utils/domUtils";

interface Props {
  label: string;
  name: string;
  id: string;
  type?: "text" | "email" | "password";
  placeholder?: string;
  prefix?: string;
  autoComplete?: string;
  required?: boolean;
  error?: string;
}

function Input(props: Props & HTMLProps<HTMLInputElement>): JSX.Element {
  const [field, meta] = useField(props);

  return (
    <div className={props.className}>
      <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <div className={classNames(props.prefix?.length ? "flex" : "relative", "mt-1")}>
        {props.prefix && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {props.prefix}
          </span>
        )}

        <input
          {...field}
          {...props}
          className={classNames(
            props.disabled ? "bg-gray-100" : "",
            meta.touched && meta.error ? "border-red-300" : "border-gray-300",
            props.prefix?.length
              ? "flex-1 rounded-none rounded-r-md"
              : "appearance-none rounded-md",
            "border w-full min-w-0 px-3 py-2 block shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-sm"
          )}
          placeholder={props.placeholder}
        />
      </div>
      {(meta.touched && meta.error) || props.error ? (
        <p className="text-sm text-red-600 mt-1" id={`${props.name}-error`}>
          {meta.error || props.error}
        </p>
      ) : null}
    </div>
  );
}

export function TextInput(props: Props & HTMLProps<HTMLInputElement>): JSX.Element {
  return <Input type="text" {...props} />;
}

export function PasswordInput(props: Props & HTMLProps<HTMLInputElement>): JSX.Element {
  return <Input type="password" {...props} />;
}

export function EmailInput(props: Props & HTMLProps<HTMLInputElement>): JSX.Element {
  return <Input type="email" {...props} />;
}
