import { addMethod, string } from "yup";
import { isPossiblePhoneNumber } from "libphonenumber-js/min";

declare module "yup" {
  export interface StringSchema {
    /**
     * Check for phone number validity.
     *
     * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
     */
    phone(errorMessage?: string): StringSchema;
  }
}

addMethod(string, "phone", function (errorMessage = "${path} must be a valid phone number.") {
  return this.test("phone", errorMessage, (value) => {
    try {
      if (!value) {
        return true;
      }

      return isPossiblePhoneNumber(value);
    } catch (e) {
      return false;
    }
  });
});
