import { HTMLProps } from "react";

export default function Badge({
  className,
  children,
  ...props
}: HTMLProps<HTMLSpanElement>): JSX.Element {
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${className}`}
      {...props}
    >
      {children}
    </span>
  );
}
