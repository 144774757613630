import { UserSignInResponse } from "../types/api";

const sessionKey = "__swoppicard_session__";

const getUser = (): UserSignInResponse => {
  const rememberMe = localStorage.getItem("rememberMe") === "true";

  if (rememberMe) {
    return JSON.parse(localStorage.getItem(sessionKey)!);
  } else {
    return JSON.parse(sessionStorage.getItem(sessionKey)!);
  }
};

const authenticate = (user: UserSignInResponse): void => {
  const rememberMe = localStorage.getItem("rememberMe") === "true";

  if (rememberMe) {
    localStorage.setItem(sessionKey, JSON.stringify(user));
  } else {
    sessionStorage.setItem(sessionKey, JSON.stringify(user));
  }
};

const logout = (): void => {
  localStorage.removeItem("rememberMe");
  localStorage.removeItem(sessionKey);
  sessionStorage.removeItem(sessionKey);
};

export { getUser, authenticate, logout };
